import React, { useEffect } from "react"
import { useAppContext } from "../context/appContext"
import { HashRouter, Link } from "react-router-dom"

function QuestonCard() {
  const { test, currentQuestion, totalQuestions, nextQuestion, submitTest } =
    useAppContext()
  const clickHandler = async (e) => {
    if (currentQuestion + 1 === totalQuestions) {
      // toast.success("wait till we have submit action...")
      await submitTest(e.target.value)
    } else {
      await nextQuestion(e.target.value)
    }
  }

  return (
    <div className="card sm:w-[36rem] md:w-[46em] lg:w-[54em] bg-primary text-primary-content mx-2">
      {test.questions[currentQuestion].questionPic && (
        <figure>
          <img src={test.questions[currentQuestion].questionPic} alt="Shoes" />
        </figure>
      )}

      <div className="card-body items-center text-center">
        <h2 className="card-title">
          {test.questions[currentQuestion].question}
        </h2>
        {/* <p>We are using cookies for no reason.</p> */}
        <div className="card-actions justify-end ">
          <ul className="flex flex-col space-y-3">
            {test.questions[currentQuestion].link && (
              <a className="badge-accent" href={test.questions[currentQuestion].link} target="_blank" >Link</a>
            )}
            {test.questions[currentQuestion].examples && <h4>Examples:</h4>}
            {test.questions[currentQuestion].examples &&
              test.questions[currentQuestion].examples.map((i) => (
                <li key={i.example}>
                  <kbd className="kbd" value={i.example}>
                    {i.example}
                  </kbd>
                </li>
              ))}
            {test.questions[currentQuestion].answerOptions.map((i) => (
              <li key={i.answerText}>
                <button
                  className="btn  btn-outline w-full"
                  value={i.answerText}
                  onClick={clickHandler}
                >
                  {i.answerText}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default QuestonCard
