import React from "react"
import { useAppContext } from "../context/appContext"
import { useNavigate } from "react-router-dom"


function Thankyou() {
  const navigate = useNavigate()
  const { totalQuestions, correctAnswers, reset } =
    useAppContext()
  
  const goHome = () => {
    reset()
    navigate("/")
  }
  return (
    <div className="text-neutral text-center text-2xl">
      You have <span className="text-2xl text-primary">{correctAnswers} </span>
      {""}
      correct answers out of{" "}
      <span className="text-2xl text-primary">{totalQuestions}</span>
      <div onClick={goHome}>
        <button  className="btn btn-primary mt-6">Start New Quiz</button>
      </div>
    </div>
  )
}

export default Thankyou
