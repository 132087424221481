export const ALERT = 'ALERT'
export const LOADING = "LOADING"
export const TEST_CODE_BEGIN = "TEST_CODE_BEGIN"
export const TEST_CODE_SUCCESS = "TEST_CODE_SUCCESS"
export const TEST_CODE_ERROR = "TEST_CODE_ERROR"
export const NEXT_QUESTION_BEGIN = "NEXT_QUESTION_BEGIN"
export const NEXT_QUESTION_SUCCESS = "NEXT_QUESTION_SUCCESS"
export const NEXT_QUESTION_ERROR = "NEXT_QUESTION_ERROR"
export const TEST_SUBMIT_BEGIN = "TEST_SUBMIT_BEGIN"
export const TEST_SUBMIT_SUCCESS = "TEST_SUBMIT_SUCCESS"
export const TEST_SUBMIT_ERROR = "TEST_SUBMIT_ERROR"
export const RESET = "RESET"