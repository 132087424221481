import React, { useEffect } from "react"
import { useAppContext } from "../context/appContext"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

function Start() {
  const navigate = useNavigate()
  const { testCode, test } = useAppContext()
  const submitHandler = async (e) => {
    e.preventDefault()
    if (!e.target.test.value) {
      toast.error("Please enter your test code!")
    e.target.test.value = ""

      return
    } else {
      await testCode(e.target.test.value)
    e.target.test.value = ""

    }
  }
  useEffect(() => {
    if (test) {
      navigate("/test")
    }
  }, [test])

  return (
    <div className="card w-96 bg-primary text-primary-content">
      <div className="card-body items-center text-center">
        <h2 className="card-title"></h2>
        <p className="text-lg font-bold">Please enter your quiz code to start</p>
        <p className="text-sm">If you don't have a code, please reach out to Khaled or try the code for demonstration: <span className="text-accent">fc111</span></p>
        <form onSubmit={submitHandler}>
          <input
            name="test"
            type="text"
            placeholder="Enter your code!"
            className="input w-full max-w-xs mb-2 "
          />
          <div className="card-actions justify-center">
            <button className="btn btn-outline mb-[-0.7rem]">Start</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Start
