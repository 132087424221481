import React, { useReducer, useContext } from "react"
import reducer from "./reducer"
import {
  ALERT,
  TEST_CODE_BEGIN,
  TEST_CODE_SUCCESS,
  TEST_CODE_ERROR,
  NEXT_QUESTION_BEGIN,
  NEXT_QUESTION_SUCCESS,
  NEXT_QUESTION_ERROR,
  TEST_SUBMIT_BEGIN,
  TEST_SUBMIT_SUCCESS,
  TEST_SUBMIT_ERROR,
  RESET,
} from "./action"

import tests from "../tests.json"

export const initialState = {
  loading: false,
  alert: false,
  alertText: "",
  test: "",
  correctAnswers: 0,
  result: 0
}

const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const showAlert = () => {
    dispatch({ type: ALERT })
  }

  const testCode = async (d) => {
    dispatch({ type: TEST_CODE_BEGIN })
    try {
      const data = await tests.find((i) => i.code === d)
      if (data != undefined) {
        dispatch({
          type: TEST_CODE_SUCCESS,
          payload: data,
        })
      } else {
        throw new Error("not found")
      }
    } catch (error) {
      dispatch({ type: TEST_CODE_ERROR })
    }
  }

  const nextQuestion = async (d) => {
    dispatch({ type: NEXT_QUESTION_BEGIN })
    try {
      dispatch({ type: NEXT_QUESTION_SUCCESS, payload: d })
    } catch (error) {
      dispatch({ type: NEXT_QUESTION_ERROR })
    }
  }

  const submitTest = async (d) => {
    dispatch({ type: TEST_SUBMIT_BEGIN })
    try {
      dispatch({type: TEST_SUBMIT_SUCCESS, payload: d})
    } catch (error) {
      dispatch({type: TEST_SUBMIT_ERROR})
    }
  }

  const reset = async (d) => {
    dispatch({ type: RESET })
    // try {
    //   dispatch({ type: TEST_SUBMIT_SUCCESS, payload: d })
    // } catch (error) {
    //   dispatch({ type: TEST_SUBMIT_ERROR })
    // }
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        showAlert,
        testCode,
        nextQuestion,
        submitTest,
        reset,
        
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
