import "./App.css"
import { ToastContainer } from "react-toastify"
import Start from "./components/Start"
import Test from "./components/Test"
import Footer from "./layouts/Footer"
import Navbar from "./layouts/Navbar"
import tests from "./tests.json"
import { useAppContext } from "./context/appContext"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Error from './components/Error'
import Thankyou from "./components/Thankyou"

function App() {
  const { test, loading, alert, alertText } = useAppContext()
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen" data-theme="cmyk">
        {/* navbar */}
        <Navbar />
        <ToastContainer />
        {/* body */}

        <div className="flex-grow flex justify-center items-center bg-white">
          <Routes>
            <Route path="/" element={<Start />} />
            <Route path="/thanks" element={<Thankyou />} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<Error />} />
          </Routes>

          {/* {test ? <Test /> : <Start />} */}

          
        </div>
        {/* footer */}
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
