import {
  ALERT,
  TEST_CODE_BEGIN,
  TEST_CODE_SUCCESS,
  TEST_CODE_ERROR,
  NEXT_QUESTION_BEGIN,
  NEXT_QUESTION_SUCCESS,
  NEXT_QUESTION_ERROR,
  TEST_SUBMIT_BEGIN,
  TEST_SUBMIT_SUCCESS,
  TEST_SUBMIT_ERROR,
  RESET,
} from "./action"
import { toast } from "react-toastify"
const reducer = (state, action) => {
  switch (action.type) {
    case ALERT:
      toast.error("from reducer", {
        toastId: "1",
      })
      return {
        ...state,
      }
    case TEST_CODE_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case TEST_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        test: action.payload,
        currentQuestion: 0,
        totalQuestions: action.payload.questions.length,
        correctAnswers: 0,
      }
    case TEST_CODE_ERROR:
      toast.error("Please enter a valid code...", {
        toastId: "1",
      })
      return {
        ...state,
        laoding: false,
      }

    case NEXT_QUESTION_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case NEXT_QUESTION_SUCCESS:
      var cTest = state.test
      var cCurrentQuestion = state.currentQuestion
      if (action.payload === cTest.questions[cCurrentQuestion].correctAnswer) {
        return {
          ...state,
          loading: false,
          currentQuestion: (state.currentQuestion += 1),
          correctAnswers: (state.correctAnswers += 1),
        }
      } else {
        return {
          ...state,
          loading: false,
          currentQuestion: (state.currentQuestion += 1),
        }
      }

    case NEXT_QUESTION_ERROR:
      toast.error("Error while processing next question...", {
        toastId: "1",
      })
      return {
        ...state,
        laoding: false,
      }
    
    case RESET:
      return {
        state : {
          
          loading: false,
          alert: false,
          alertText: "",
          test: "",
          correctAnswers: 0,
          result: 0,
        }
        
        // ...state,
        // cTest: false,
        // currentQuestion: false,
        //   correctAnswers: false,

      }

    case TEST_SUBMIT_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case TEST_SUBMIT_SUCCESS:
      var cTest = state.test
      var cCurrentQuestion = state.currentQuestion
      if (action.payload === cTest.questions[cCurrentQuestion].correctAnswer) {
        return {
          ...state,
          loading: false,
          currentQuestion: (state.currentQuestion += 1),
          correctAnswers: (state.correctAnswers += 1),
          result: (state.correctAnswers += 1),
        }
      } else {
        return {
          ...state,
          loading: false,
          currentQuestion: (state.currentQuestion += 1),
          result: state.correctAnswers,
        }
      }
    case TEST_SUBMIT_ERROR:
      toast.error("Error while submitting test...", {
        toastId: "1",
      })
      return {
        ...state,
        laoding: false,
      }

    default:
      // return state
      throw new Error(`Action not found: ${action.type}`)
  }
}

export default reducer
