import React, {useEffect} from 'react'
import tests from "../tests.json"
import QuestonCard from './QuestonCard'
import { useAppContext } from "../context/appContext"
import { useNavigate } from "react-router-dom"
import Thankyou from './Thankyou'



function Test() {
  let navigate = useNavigate()

  const { result, test, currentQuestion, totalQuestions, correctAnswers } = useAppContext();

    
    
  return (
    <div className="flex flex-col justify-center space-y-5">
      <h2 className="text-center text-4xl text-primary">{test.name}</h2>
      {currentQuestion === totalQuestions ? (
        <Thankyou />
      ) : (
        <>
          <p className="text-primary self-end px-2">
            Correct Answers: {correctAnswers}
          </p>

          <progress
            className="progress progress-primary w-full px-2"
            value={currentQuestion + 0.5}
            max={test.questions.length}
          ></progress>
          <QuestonCard />
        </>
      )}

      {/* <button className='btn btn-primary'>Next</button> */}
    </div>
  )
}

export default Test